@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&display=swap');

/* ESTILOS DE LA BARRA DE NAVEGACION */
.navegacion{
    background-color: rgb(238, 239, 240);
}

#marca-navegacion{
    display: inline;
    font-family: 'Montserrat', sans-serif;
    font-size: x-large;
    color: black;
    text-shadow: 2px 2px 4px lightgray;
}

.enlace-navegacion{
    margin: 1em;
    font-family: 'Montserrat', sans-serif;
    font-size: large;
    color: black;
    text-shadow: 2px 2px 4px lightgray;
}

.etiqueta-link{
    text-decoration: none;
}

/* ESTILO COMPOONENTE INICIO (TARJETAS) */
.tarjetas-inicio{
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
    /* transition: transform 0.5s; */
}

/* .tarjetas-inicio:hover{
    transform: scale(1.02);
    z-index: 1;
} */

/*  */

.contenedorImagen{
    position: relative;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 25%,rgba(255,255,255,1) 100%);
}
.imagen{    
    width: 100%;
    position:relative;
    z-index:-1;
}
.titulo-centrado{
    color: white;
    text-shadow: 1px 2px 2px #757575;
    /* background: rgba(0, 0, 0, 0.3); */
    border-radius: 5px;
    /* font-size: 300%; */
    font-size: 3em;
    padding: 1%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Montserrat', sans-serif;
    /* width: 100%; */
}

/* .contenedorContenido{
    text-align: center;
    margin: auto;
} */

.titulo-principal{
    color: black;
    /* text-shadow: 1px 2px 2px #757575; */
    border-radius: 2%;
    font-size: 3em;
    padding: 1%;
    /* position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%); */
    font-family: 'Montserrat', sans-serif;
}

.titulo{
    text-align: center;
    margin: auto;
    margin-bottom: 0.5em;
    color: black;
    font-size: 2em;
    font-family: 'Montserrat', sans-serif;
    
}

.contenedor-info-conocenos{
    font-size: x-large;
    color: gray;
    font-family: 'Poppins', sans-serif;
    margin: auto;
    text-align: justify;
    width: 75%;
}

/* ESTILO PARA LAS TARJETAS DE INICIO */

/* .titulo-tarjetas{
    text-align: center;
    margin: auto;
    margin-bottom: 0.5em;
    color: black;
    font-size: 2em;
    font-family: 'Montserrat', sans-serif;
} */

.texto-tarjetas{
    font-size: large;
    color: gray;
    font-family: 'Poppins', sans-serif;
    margin: auto;
    text-align: justify;
}

.footer-tarjetas{
    font-size: large;
    color: gray;
    font-family: 'Poppins', sans-serif;
}

/* CARRUSEL */

.carrusel{
    width: 85%;
    margin-top: 8em;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
    /* box-shadow: -8px 8px 10px gray; */
}

.caption-carrusel{
    /* padding: 0px; */
    font-size: xx-large;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
}

/* FOOTER */

.contenedor-footer{
    background-color: red;
    width: 100%;
    height: 100%;
}

/* COMPONENTE SERVICIOS */

.contenedor-tarjetas-servicios{
    font-size: x-large;
    color: gray;
    font-family: 'Poppins', sans-serif;
    margin: auto;
    text-align: justify;
    padding: 1em;
    padding-bottom: 3em;
}

.tarjeta-servicio{
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
}

/* .tarjeta-imagen img:hover{
    transform: scale(1.05);
} */

/* COMPONENTE CONTACTO */
.tarjeta-contacto{
    width: 50%;
    margin: auto;
    margin-top: 3.5em;
}

.modal-content {
    border-radius: 10px !important;
}

.banner-container {
    display: flex;
    flex-direction: row;
    width: 86.5%;
    justify-content: center;
    align-items: center;
    margin: auto;
}

@media (max-width: 800px) {
    .carrusel{
        width: 95%;
    }

    .caption-carrusel{
        font-size: x-large;
    }

    .tarjeta-contacto{
        width: 80%;
    }

    .contenedor-servicios{
        width: 90%;
    }

    .imagen-servicios{
        width: 90%;
    }

    .contenedor-tarjetas-servicios{
        font-size: large;
    }

    .banner-container {
        width: 95.5%;
    }
}

@media (max-width: 575px) {

    .caption-carrusel{
        font-size: medium;
    }

    .contenedor-servicios{
        width: 100%;
    }

    .imagen-servicios{
        width: 100%;
    }

    .banner-container {
        width: 100%;
        flex-direction: column;
    }
}