 .App {
  text-align: center;
  min-height: 100vh;

}
html{
  height: 100%;
}

body{
  padding-top: 6em;
}
/* body{
  border: 3px dashed #008000;
} */
footer{
 background: #333;
 color: white;
 padding: 1em;
 /* margin-top: auto;  */
 position: sticky;
 top: 100%;
}

img{
  -webkit-user-drag: none;
}